var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',{attrs:{"value":_vm.menu,"disabled":!_vm.isAllowedToUse,"content-class":"v-dialog__form","width":"440"},on:{"update:value":function($event){_vm.menu=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('app-action-btn',_vm._g({class:_vm.isShowTitleIcon ? '' : 'nav-card-action',attrs:{"data-test":_vm.dataTest,"access":_vm.canUse,"show-tooltip":"","bottom":"","wrapper-class":_vm.isShowTitleIcon ? '' : 'collection-action-button__header-text'},scopedSlots:_vm._u([{key:"iconWrapper",fn:function(){return [(_vm.isShowTitleIcon)?_c('v-icon',{attrs:{"size":"20","color":"lightBlack"}},[_vm._v(" mdi-account-multiple-plus ")]):_vm._e()]},proxy:true}],null,true)},on),[(_vm.title)?_c('span',{class:[!_vm.isShowTitleIcon && 'collection-action-button__header-span']},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.textHeaderFollow)+" ")]),_c('v-icon',{attrs:{"color":"#fff"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',{staticClass:"items-wrapper"},[_c('p',{staticClass:"sub-text-info text--secondary ma-0"},[_vm._v(" "+_vm._s(_vm.textTopInput)+" ")]),_c('v-autocomplete',{attrs:{"attach":"","placeholder":"Choose Workspace to follow","multiple":"","allow-overflow":false,"menu-props":{
          maxHeight: '220px',
        },"items":_vm.filteredWorkspacesList,"item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
        var attrsSlot = ref.attrs;
return [_c('v-tooltip',{attrs:{"disabled":!item.disabled,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({attrs:{"wrap":""}},'v-layout',attrs,false),on),[_c('v-list-item-action',{staticClass:"v-list-item__action-checkbox"},[_c('v-checkbox',{class:{'unchecked-color':_vm.saveUnfollowedIds.includes(item.id)},attrs:{"color":_vm.saveFollowedIds.includes(item.id) ? 'success': '',"disabled":item.disabled},model:{value:(attrsSlot.inputValue),callback:function ($$v) {_vm.$set(attrsSlot, "inputValue", $$v)},expression:"attrsSlot.inputValue"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{style:([item.disabled ? { 'color': 'rgba(0, 0, 0, 0.38) !important' } : {}])},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textToDisabled))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"sticky-bottom pt-6 pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"lightBlue"},on:{"click":function($event){_vm.menu=false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"lightBlue","disabled":_vm.disabledFollowBtn},on:{"click":_vm.handleOnDoneClick}},[_vm._v(" Done ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }