<template>
  <app-dialog
    v-model="menu"
    :value.sync="menu"
    :disabled="!isAllowedToUse"
    content-class="v-dialog__form"
    width="440">
    <template v-slot:activator="{ on }">
      <app-action-btn
        :data-test="dataTest"
        :access="canUse"
        show-tooltip
        bottom
        :wrapper-class="isShowTitleIcon ? '' : 'collection-action-button__header-text'"
        :class="isShowTitleIcon ? '' : 'nav-card-action'"
        v-on="on">
        <template #iconWrapper>
          <v-icon
            v-if="isShowTitleIcon"
            size="20"
            color="lightBlack">
            mdi-account-multiple-plus
          </v-icon>
        </template>
        <span
          v-if="title"
          :class="[!isShowTitleIcon && 'collection-action-button__header-span']">
          {{ title }}
        </span>
      </app-action-btn>
    </template>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          {{ textHeaderFollow }}
        </div>
        <v-icon
          color="#fff"
          @click="menu = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="items-wrapper">
        <p
          class="sub-text-info text--secondary ma-0">
          {{ textTopInput }}
        </p>
        <v-autocomplete
          v-model="selected"
          attach
          placeholder="Choose Workspace to follow"
          multiple
          :allow-overflow="false"
          :menu-props="{
            maxHeight: '220px',
          }"
          :items="filteredWorkspacesList"
          item-value="id"
          item-text="name">
          <template #item="{item,attrs: attrsSlot}">
            <v-tooltip
              :disabled="!item.disabled"
              bottom>
              <template #activator="{ on, attrs }">
                <v-layout
                  wrap
                  v-bind="attrs"
                  v-on="on">
                  <v-list-item-action class="v-list-item__action-checkbox">
                    <v-checkbox
                      v-model="attrsSlot.inputValue"
                      :class="{'unchecked-color':saveUnfollowedIds.includes(item.id)}"
                      :color="saveFollowedIds.includes(item.id) ? 'success': ''"
                      :disabled="item.disabled" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      :style="[item.disabled ? { 'color': 'rgba(0, 0, 0, 0.38) !important' } : {}]">
                      {{
                        item.name
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-layout>
              </template>
              <span>{{ textToDisabled }}</span>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions
        class="sticky-bottom pt-6 pb-4">
        <v-spacer />
        <v-btn
          outlined
          color="lightBlue"
          @click="menu=false">
          Cancel
        </v-btn>
        <v-btn
          color="lightBlue"
          :disabled="disabledFollowBtn"
          @click="handleOnDoneClick">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>
<script>
export default {
  name: 'AppDialogLayoutToFollowTheEssence',
  props: {
    canUse: {
      type: Object,
      default: () => {},
    },
    isShowTitleIcon: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Follow',
    },
    textTopInput: {
      type: String,
      default: '',
    },
    textHeaderFollow: {
      type: String,
      default: '',
    },
    textToDisabled: {
      type: String,
      default: '',
    },
    followMenu: {
      type: Boolean,
      default: false,
    },
    selectedWorkspaces: {
      type: Array,
      default: () => [],
    },
    filteredWorkspacesList: {
      type: Array,
      default: () => [],
    },
    followedIds: {
      type: Array,
      default: () => [],
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  computed: {
    isAllowedToUse() {
      const { allowed } = this.canUse || {
      };
      return allowed;
    },
    selected: {
      get() {
        return this.selectedWorkspaces;
      },
      set(val) {
        this.$emit('update-selected-workspaces', val);
      },
    },
    menu: {
      get() {
        return this.followMenu;
      },
      set(val) {
        this.$emit('update-dialog', val);
      },
    },
    disabledFollowBtn() {
      const { saveFollowedIds, saveUnfollowedIds } = this;
      return !saveFollowedIds.length && !saveUnfollowedIds.length;
    },
    saveFollowedIds() {
      const { selectedWorkspaces = [], followedIds = [] } = this;
      return selectedWorkspaces.filter(id => !followedIds.includes(id));
    },
    saveUnfollowedIds() {
      const { selectedWorkspaces = [], followedIds = [] } = this;
      return followedIds.filter(id => !selectedWorkspaces.includes(id));
    },
  },
  methods: {
    handleOnDoneClick() {
      const { saveFollowedIds, saveUnfollowedIds } = this;
      this.$emit('on-done', {
        saveFollowedIds,
        saveUnfollowedIds,
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .sub-text-info {
    font-size: 13px;
  }
  .v-autocomplete {
    padding-top: 0 !important;
    ::v-deep.v-select__slot {
      max-height: 85px;
      overflow-y: auto;
      overflow-x: hidden
    }
  }
  ::v-deep .v-dialog__show {
    .v-dialog__container {
      display: initial !important;
    }
  }
  div[role="dialog"].v-dialog__container {
    display: initial !important;
    padding: 0;
  }
  .items-wrapper {
    min-height: 360px;
    max-height: 50vh;
  }
  ::v-deep .v-list-item--disabled {
    pointer-events: auto !important;
  }
</style>
